import axios from "axios";
import API from "../../../services/API";
import { trackPromise } from "react-promise-tracker";
import Formatter from "../../../services/Formatter";
import exportFromJSON from 'export-from-json'  


// Fetch Request List
export const fetchDtrSummary = ( data = null ) => {
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/team",
            params : data
        })
        .then(result => {
            dispatch({
                'type'      : 'FETCH_DTR_SUMMARY_SUCCESS', 
                'dtrSummary'  : result.data.content,
            })
            if (result.data.content.has_next_page) {
                var btnGenerate = document.getElementById('btn-generate');
                btnGenerate.click();
            }
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } ) 
        });
    }
}

// Fetch Request new List
export const fetchNewDtrSummary = ( data = null ) => {
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/new_team",
            params : data
        })
        .then(result => {
            dispatch({
                'type'      : 'FETCH_NEW_DTR_SUMMARY_SUCCESS', 
                'dtrSummary'  : result.data.content,
            })
            if (result.data.content.has_next_page) {
                var btnGenerate = document.getElementById('btn-generate');
                btnGenerate.click();
            }
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } ) 
        });
    }
}

// Fetch Request new List
export const fetchDtrConflict = ( data = null ) => {
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/dtr_conflict",
            params : data
        })
        .then(result => {
            const data = result.data.content.dtrItems;
            const fileName = 'Dtr_Conflict_Report';
            const exportType = 'csv';
            exportFromJSON({ data, fileName,exportType });
            dispatch({
                'type'      : 'FETCH_DTR_CONFLICT_REPORT_SUCCESS', 
                'dtrConflict'  : result.data.content,
            })
            if (result.data.content.has_next_page) {
                var btnGenerate = document.getElementById('btn-generate');
                btnGenerate.click();
            }
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } ) 
        });
    }
}

// Fetch Request new List
export const fetchDtrMismatch = ( data = null ) => {
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/dtr/export_confilt_dtr",
            params : data
        })
        .then(result => {
            dispatch({
                'type'      : 'FETCH_NEW_DTR_SUMMARY_SUCCESS', 
                'dtrSummary'  : result.data.content,
            })
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } ) 
        });
    }
}






export const exportDtrSummary = ( data = null ) => {
    // console.log('Params', data)
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/export",
            params : data
        })
        .then(result => {
            // console.log(result.data.content)
            if (result.data.content) {
                dispatch({
                    'type'      : 'FETCH_DTR_EXPORT_BACTH_SUCCESS', 
                    'dtrSummary'  : result.data.content,
                })
                //generate next page and append to the previous result
                if (result.data.content.has_next_page) {
                    var btnExport = document.getElementById('btn-export-' + (data?.export ? data.export : (data?.department_id ? 'department' : 'all')));
                    btnExport.click();
                }
            } else {
                dispatch({
                    'type'      : 'FETCH_DTR_EXPORT_SUCCESS',
                    'data'      : result.data
                })
            }
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } )
        });
    }
}

export const exportNewDtrSummary = ( data = null) => {
    // console.log('Params', data)
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/new_export",
            params : data
        })
        .then(result => {
            console.log(result.data.content)
            if (result.data.content) {
                dispatch({
                    'type'      : 'FETCH_DTR_EXPORT_BACTH_SUCCESS', 
                    'dtrSummary'  : result.data.content,
                })
                //generate next page and append to the previous result
                if (result.data.content.has_next_page) {
                    var btnExport = document.getElementById('btn-export-' + (data?.export ? data.export : (data?.department_id ? 'department_new' : 'all_new')));
                    btnExport.click();
                }
            } else {
                dispatch({
                    'type'      : 'FETCH_DTR_EXPORT_SUCCESS',
                    'data'      : result.data
                })
            }
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } )
        });
    }
}


export const exportNewDtrSummary1 = ( data = null) => {
    // console.log('Params', data)
    return (dispatch, getState) => {
        API.call({
            method: "get",
            url: "/report/dtr_summary/export_dtr_conflict",
            params : data
        })
        .then(result => {
            const data = result.data;
            const fileName = 'Dtr_Conflict_Report'  ;
            const exportType = 'csv';
            exportFromJSON({ data, fileName,exportType });
            // console.log(result.data.content)
           
                // dispatch({
                //     'type'      : 'FETCH_DTR_CONFLICT_EXPORT_SUCCESS',
                //     'data'      : result.data
                // })
        })
        .catch(e => {
            dispatch( {
                'type'      : 'FETCH_DTR_SUMMARY_BATCH_ERROR', 
                'e'  : e,
            } )
        });
    }
}


